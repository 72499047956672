.LogoSection-wrapper {
  background-color: rgb(17, 17, 17);
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:after {
    content: "";
    width: 200%;
    height: 200%;
    bottom: 0;
    left: 0;
    position: absolute;
    opacity: 0.25;
    background: radial-gradient(circle, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
  }
  & .LogoMain {
    max-width: 40%;
    min-width: 260px;
  }
}
