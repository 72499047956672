@import "colors";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.page {
  position: relative;
  user-select: none;
  display: flex;
  flex-direction: column;
  min-height: 100vh;


  &__content {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;

    flex-grow: 1;
  }
}
